(function () {
  'use strict';
  angular
    .module('frontendApp', ['ngAnimate',
      'ngCookies', 'ngTouch', 'ngSanitize',
      'ngMessages', 'ngAria', 'ngResource',
      'ui.router', 'ui.bootstrap', 'toastr',
      'LocalStorageModule', 'sap.imageloader',
      /*'angular-inview',*/
      'ngMap', 'fox.scrollReveal',
      'angularSmoothscroll', 'angular-owl-carousel-2',
      'fox.scrollReveal']);

})();

'use strict';
angular
  .module('frontendApp')
  .factory('ContentService', function ($http, $rootScope) {
    return {
      // GETDATA USAGE
      // CONFIG
      //    'Model' (String)
      //    'Params' (Object)
      //       |_'per_page'(int)
      //       |_'page' (int)
      //       |_'orderBy' (string)
      //       |_'selected' (find register searching by ID)
      //       |_'filters' (Object)
      //            |_'name of the field to be searched for' (Object)
      //                 |_'operator' (String) 'like', '=', '>', '>=', '<', '<='
      //                 |_'value' (String | int | date)
      // USE EXAMPLE
      //     ContentService.getdata( 'homenews', {
      //        per_page: 5,
      //        page: 1,
      //        filters: {
      //          title: {operator: 'like', value: 'World'},
      //          status: {value: 1},
      //          created_at: {operator: '>', value: '2018-01-01'}
      //        }
      //      })
      //      .success(function (res) { console.log('success', res); });
      getdata: function (table, params) {
        console.log('BEFORE REQUEST', table, params)
        return $http.post($rootScope.api + '/api/' + table + '/getdata', params);
      },
      // RETURNS ALL THE WEB CONTENTS
      webcontent: function (state) {
        var params = {
          state : state
        };
        return $http.post($rootScope.api + '/api/webcontent', params);
      },
      // WEB FORMS SERVICE
      sendform: function (params) {
        return $http.post($rootScope.api + '/api/sendform', params);
      },
      // RETURN TRANSLATIONS
      fixedcontent: function () {
        return $http.get('assets/fixedcontent.json');
      }


    };
  });

(function () {
  'use strict';
  angular
    .module('frontendApp')
    //establece altura a pantalla completa
    .directive('windowheight', function () {
      return {
        restrict: 'A',
        link: function (scope, element) {
          $(window).resize(function () {
            element.css({minHeight: $(window).height()});
            //console.log('exit')
          }).resize();
        }
      };
    })
    //carga video
    .directive('myYoutube', function ($sce) {
      return {
        restrict: 'EA',
        scope: {code: '='},
        replace: true,
        template: '<div style="height:400px;"><iframe style="overflow:hidden;height:100%;width:100%" width="100%" height="100%" src="{{url}}" frameborder="0" allowfullscreen></iframe></div>',
        link: function (scope) {
          console.log('here');
          scope.$watch('code', function (newVal) {
            if (newVal) {
              scope.url = $sce.trustAsResourceUrl("http://www.youtube.com/embed/" + newVal);
            }
          });
        }
      };
    })
    //Iguala altura entre elementos
    //targetclass = nombre de la clase para igualar alturas de elementos
    .directive('equalheight', function ($timeout) {
      return {
        restrict: 'A',
        scope: {
          targetclass: '@'
        },
        link: function (scope, element) {
          $(window).resize(function () {
            console.log(scope.targetclass)
            $timeout(function () {
              var x = document.getElementsByClassName(scope.targetclass);
              var temp = 0;
              for (var i = 0; i < x.length; i++) {
                if (temp < x[i].offsetHeight) {
                  temp = x[i].offsetHeight;
                }
              }
              console.log('maximo altura detectada', temp);
              element.css({minHeight: temp});
            }, 1000);
          }).resize();
        }
      };
    })
    //establece una altura personalizada partiendo del alto de pantalla o deua medida especifica
    .directive('customheight', function () {
      return {
        restrict: 'A',
        scope: {
          minus: '=',
          plus: '=',
          equal: '='
        },
        link: function (scope, element) {
          $(window).resize(function () {
            var customHeight = $(window).height();
            if (scope.equal) {
              customHeight = scope.equal;
            }
            if (scope.minus) {
              customHeight -= scope.minus;
            }
            if (scope.plus) {
              customHeight += scope.plus;
            }
            element.css({minHeight: customHeight});
          }).resize();
        }
      };
    })

})();

'use strict';
angular
  .module('frontendApp')
  .controller('HomeController', function (ContentService, ImageLoader, $window,  $log, $timeout, $rootScope, $scope) {
    var vm = this;
    $log.debug('HomeController');

  });

'use strict';
angular
  .module('frontendApp')
  .controller('ContactController', function (ContentService, ImageLoader, $window,  $log, $timeout, $rootScope, $scope) {
    var vm = this;
    $log.debug('ContactController');



  });

'use strict';
angular
  .module('frontendApp')
  .controller('AppController', function ($log, ContentService,$location,
                                         $rootScope, $timeout, localStorageService) {
    var vm = this;
    $log.debug('AppController');

    //Contenido fijo
    ContentService.fixedcontent().success(function (res) {
      $rootScope.fixedcontent = res;
      console.log('Contenidos fijos', $rootScope.fixedcontent)
    });
    $rootScope.getFixedContent = function(i){
      return $rootScope.fixedcontent ? $rootScope.fixedcontent[i][$rootScope.language] : '';
    };

  });

(function() {
  'use strict';

  angular
      .module('frontendApp')
      .service('webDevTec', webDevTec);

  /** @ngInject */
  function webDevTec() {
    var data = [
      {
        'title': 'AngularJS',
        'url': 'https://angularjs.org/',
        'description': 'HTML enhanced for web apps!',
        'logo': 'angular.png'
      },
      {
        'title': 'BrowserSync',
        'url': 'http://browsersync.io/',
        'description': 'Time-saving synchronised browser testing.',
        'logo': 'browsersync.png'
      },
      {
        'title': 'GulpJS',
        'url': 'http://gulpjs.com/',
        'description': 'The streaming build system.',
        'logo': 'gulp.png'
      },
      {
        'title': 'Jasmine',
        'url': 'http://jasmine.github.io/',
        'description': 'Behavior-Driven JavaScript.',
        'logo': 'jasmine.png'
      },
      {
        'title': 'Karma',
        'url': 'http://karma-runner.github.io/',
        'description': 'Spectacular Test Runner for JavaScript.',
        'logo': 'karma.png'
      },
      {
        'title': 'Protractor',
        'url': 'https://github.com/angular/protractor',
        'description': 'End to end test framework for AngularJS applications built on top of WebDriverJS.',
        'logo': 'protractor.png'
      },
      {
        'title': 'Bootstrap',
        'url': 'http://getbootstrap.com/',
        'description': 'Bootstrap is the most popular HTML, CSS, and JS framework for developing responsive, mobile first projects on the web.',
        'logo': 'bootstrap.png'
      },
      {
        'title': 'Angular UI Bootstrap',
        'url': 'http://angular-ui.github.io/bootstrap/',
        'description': 'Bootstrap components written in pure AngularJS by the AngularUI Team.',
        'logo': 'ui-bootstrap.png'
      },
      {
        'title': 'Sass (Node)',
        'url': 'https://github.com/sass/node-sass',
        'description': 'Node.js binding to libsass, the C version of the popular stylesheet preprocessor, Sass.',
        'logo': 'node-sass.png'
      }
    ];

    this.getTec = getTec;

    function getTec() {
      return data;
    }
  }

})();

(function() {
  'use strict';

  angular
    .module('frontendApp')
    .directive('acmeNavbar', acmeNavbar);

  /** @ngInject */
  function acmeNavbar() {
    var directive = {
      restrict: 'E',
      templateUrl: 'app/components/navbar/navbar.html',
      scope: {
          creationDate: '='
      },
      controller: NavbarController,
      controllerAs: 'vm',
      bindToController: true
    };

    return directive;

    /** @ngInject */
    function NavbarController(moment) {
      var vm = this;

      // "vm.creation" is avaible by directive option "bindToController: true"
      vm.relativeDate = moment(vm.creationDate).fromNow();
    }
  }

})();

(function() {
  'use strict';

  angular
    .module('frontendApp')
    .directive('acmeMalarkey', acmeMalarkey);

  /** @ngInject */
  function acmeMalarkey(malarkey) {
    var directive = {
      restrict: 'E',
      scope: {
        extraValues: '='
      },
      template: '&nbsp;',
      link: linkFunc,
      controller: MalarkeyController,
      controllerAs: 'vm'
    };

    return directive;

    function linkFunc(scope, el, attr, vm) {
      var watcher;
      var typist = malarkey(el[0], {
        typeSpeed: 40,
        deleteSpeed: 40,
        pauseDelay: 800,
        loop: true,
        postfix: ' '
      });

      el.addClass('acme-malarkey');

      angular.forEach(scope.extraValues, function(value) {
        typist.type(value).pause().delete();
      });

      watcher = scope.$watch('vm.contributors', function() {
        angular.forEach(vm.contributors, function(contributor) {
          typist.type(contributor.login).pause().delete();
        });
      });

      scope.$on('$destroy', function () {
        watcher();
      });
    }

    /** @ngInject */
    function MalarkeyController($log, githubContributor) {
      var vm = this;

      vm.contributors = [];

      activate();

      function activate() {
        return getContributors().then(function() {
          $log.info('Activated Contributors View');
        });
      }

      function getContributors() {
        return githubContributor.getContributors(10).then(function(data) {
          vm.contributors = data;

          return vm.contributors;
        });
      }
    }

  }

})();

(function() {
  'use strict';

  angular
    .module('frontendApp')
    .factory('githubContributor', githubContributor);

  /** @ngInject */
  function githubContributor($log, $http) {
    var apiHost = 'https://api.github.com/repos/Swiip/generator-gulp-angular';

    var service = {
      apiHost: apiHost,
      getContributors: getContributors
    };

    return service;

    function getContributors(limit) {
      if (!limit) {
        limit = 30;
      }

      return $http.get(apiHost + '/contributors?per_page=' + limit)
        .then(getContributorsComplete)
        .catch(getContributorsFailed);

      function getContributorsComplete(response) {
        return response.data;
      }

      function getContributorsFailed(error) {
        $log.error('XHR Failed for getContributors.\n' + angular.toJson(error.data, true));
      }
    }
  }
})();

(function() {
  'use strict';

  angular
    .module('frontendApp')
    .controller('MainController', MainController);

  /** @ngInject */
  function MainController($timeout, webDevTec, toastr) {
    var vm = this;

    vm.awesomeThings = [];
    vm.classAnimation = '';
    vm.creationDate = 1516719002839;
    vm.showToastr = showToastr;

    activate();

    function activate() {
      getWebDevTec();
      $timeout(function() {
        vm.classAnimation = 'rubberBand';
      }, 4000);
    }

    function showToastr() {
      toastr.info('Fork <a href="https://github.com/Swiip/generator-gulp-angular" target="_blank"><b>generator-gulp-angular</b></a>');
      vm.classAnimation = '';
    }

    function getWebDevTec() {
      vm.awesomeThings = webDevTec.getTec();

      angular.forEach(vm.awesomeThings, function(awesomeThing) {
        awesomeThing.rank = Math.random();
      });
    }
  }
})();

(function () {
  'use strict';

  angular
    .module('frontendApp')
    .run(runBlock);

  /** @ngInject */
  function runBlock($log, $rootScope, $state, ContentService, localStorageService, $timeout, $window, $stateParams) {

    //REST API ROUTE
    //$rootScope.api = 'http://localhost:8000';
    $rootScope.api = 'http://dev-fsecretan-admin.aknatechnologies.com/restapi/public';

    //SESSION VARS MANIPULATION
    $rootScope.getSessionParam = function (key) {
      return localStorageService.get(key);
    };
    $rootScope.setSessionParam = function (key, val) {
      return localStorageService.set(key, val);
    };
    // SETTING SPANISH AS DEFAULT LANGUAGE
    if($rootScope.getSessionParam('language')) {
      $rootScope.language = $rootScope.getSessionParam('language');
    }else{
      $rootScope.language = 'es';
    }
    //VERIFY CURRENT LANGUAGE
    $timeout(function(){
      if ($stateParams.lan) {
        $rootScope.changeLanguage($stateParams.lan, $rootScope.currentState);
      }
    }, 2);
    //TRANSALATE
    $rootScope.switchText = function (target, lan) {
      return target[lan];
    };
    //CHANGE OF ROUTE SETTING A NEW LANGUAGE
    $rootScope.changeLanguage = function (value, state) {
      $rootScope.language = value;
      $rootScope.setSessionParam('language', value);
      $state.go(state, {lan: value});
    };
    //MAIL TO FUNCTION
    $rootScope.sendMail = function (emailId, subject, message) {
      $window.open("mailto:" + emailId + "?subject=" + subject + "&body=" + message, "_self");
    };
    //EXECUTE ON ROUTE CHANGE
    $rootScope.$on('$stateChangeSuccess', function (event, toState) {
      $("html, body").animate({scrollTop: 0}, 1000);
      $state.current = toState;
      $rootScope.$state = $state;
      $rootScope.currentState = $state.current.name;
      //GET RESTAPI CONTENTS
      ContentService.webcontent($rootScope.currentState)
        .success(function (res) {
          if(res.status){
            $rootScope.webcontent = res.result;
            //$rootScope.fixedcontent = $rootScope.webcontent.webfixedcontent;
            console.log('webcontent', res, res.result.data)
          }
        });
      //GET CURRENT LANGUAGE
      ($rootScope.getSessionParam('language')) ?
        $rootScope.language = $rootScope.getSessionParam('language') : $rootScope.language = 'es';
    });


  }

})();

(function() {
  'use strict';

  angular
    .module('frontendApp')
    .config(routerConfig);

  /** @ngInject */
  function routerConfig($stateProvider, $urlRouterProvider) {
    $stateProvider
      .state('home', {
        url: '/',
        templateUrl: 'app/views/home.html',
        controller: 'HomeController',
        controllerAs: 'ini'
      })
      .state('gallery', {
        url: '/:lan/gallery',
        templateUrl: 'app/views/gallery.html',
      })
      .state('contact', {
        url: '/:lan/contact/',
        templateUrl: 'app/views/contact.html',
      });

    $urlRouterProvider.otherwise('/');
  }

})();

/* global malarkey:false, moment:false */
(function() {
  'use strict';

  angular
    .module('frontendApp')
    .constant('malarkey', malarkey)
    .constant('moment', moment);

})();

(function() {
  'use strict';

  angular
    .module('frontendApp')
    .config(config);

  /** @ngInject */
  function config($logProvider, toastrConfig, $locationProvider) {
    // Enable log
    $logProvider.debugEnabled(true);
    $locationProvider.html5Mode(true).hashPrefix('#');

    // Set options third-party lib
    toastrConfig.allowHtml = true;
    toastrConfig.timeOut = 3000;
    toastrConfig.positionClass = 'toast-top-right';
    toastrConfig.preventDuplicates = true;
    toastrConfig.progressBar = true;

    //initialize wow library
    new WOW().init();
  }

})();

angular.module("frontendApp").run(["$templateCache", function($templateCache) {$templateCache.put("app/main/main.html","<div class=\"container\"><div><acme-navbar creation-date=\"main.creationDate\"></acme-navbar></div><div class=\"jumbotron text-center\"><h1>\'Allo, \'Allo!</h1><p class=\"lead\"><img src=\"assets/images/yeoman.png\" alt=\"I\'m Yeoman\"><br>Always a pleasure scaffolding your apps.</p><p class=\"animated infinite\" ng-class=\"main.classAnimation\"><button type=\"button\" class=\"btn btn-lg btn-success\" ng-click=\"main.showToastr()\">Splendid Toastr</button></p><p>With ♥ thanks to the contributions of<acme-malarkey extra-values=\"[\'Yeoman\', \'Gulp\', \'Angular\']\"></acme-malarkey></p></div><div class=\"row\"><div class=\"col-sm-6 col-md-4\" ng-repeat=\"awesomeThing in main.awesomeThings | orderBy:\'rank\'\"><div class=\"thumbnail\"><img class=\"pull-right\" ng-src=\"assets/images/{{ awesomeThing.logo }}\" alt=\"{{ awesomeThing.title }}\"><div class=\"caption\"><h3>{{ awesomeThing.title }}</h3><p>{{ awesomeThing.description }}</p><p><a ng-href=\"{{awesomeThing.url}}\">{{ awesomeThing.url }}</a></p></div></div></div></div></div>");
$templateCache.put("app/views/footer.html","<div class=\"container-fluid\"><div class=\"row\"><div class=\"col-xs-12\"><h2>Footer</h2></div></div></div>");
$templateCache.put("app/views/header.html","<div class=\"container-fluid\"><div class=\"row\"><div class=\"col-xs-12\"><h2>Header</h2></div></div></div>");
$templateCache.put("app/views/home.html","<div class=\"container-fluid\"><div class=\"row\"><div class=\"col-xs-12\"><h2>Wrapper</h2><h4 class=\"wow fadeInUp\">Hola, bienvenido</h4></div></div></div>");
$templateCache.put("app/components/navbar/navbar.html","<nav class=\"navbar navbar-static-top navbar-inverse\"><div class=\"container-fluid\"><div class=\"navbar-header\"><a class=\"navbar-brand\" href=\"https://github.com/Swiip/generator-gulp-angular\"><span class=\"glyphicon glyphicon-home\"></span> Gulp Angular</a></div><div class=\"collapse navbar-collapse\" id=\"bs-example-navbar-collapse-6\"><ul class=\"nav navbar-nav\"><li class=\"active\"><a ng-href=\"#\">Home</a></li><li><a ng-href=\"#\">About</a></li><li><a ng-href=\"#\">Contact</a></li></ul><ul class=\"nav navbar-nav navbar-right acme-navbar-text\"><li>Application was created {{ vm.relativeDate }}.</li></ul></div></div></nav>");}]);